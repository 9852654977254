@use 'colors';

.form-field-datepicker {
  .mat-mdc-input-element {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .mat-mdc-text-field-wrapper {
    background: unset !important;
  }

  .mat-mdc-form-field-icon-suffix {
    height: var(--input-element-height);
    align-self: flex-end;
    overflow: hidden;
    background-color: rgb(235 235 235 / var(--tw-bg-opacity));
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    mat-datepicker-toggle {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .mat-mdc-button-persistent-ripple {
      display: none !important;
    }

    .mat-mdc-button-touch-target,
    .mat-mdc-button-ripple,
    .mat-mdc-focus-indicator {
      height: 100%;
      width: 100%;
    }

    .mat-mdc-icon-button.mat-mdc-button-base {
      height: var(--input-element-height);
      width: var(--input-element-height);
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.datepicker-range {
    .mat-mdc-text-field-wrapper {
      background: unset !important;
      padding: 0 !important;

      .mat-mdc-form-field-focus-overlay {
        display: none;
      }

      .mat-mdc-form-field-infix {
        position: relative;

        &:before {
          content: '';
          display: block;
          width: 100%;
          height: var(--input-element-height);
          position: absolute;
          left: 0;
          bottom: 0;
          background-color: rgb(235 235 235 / var(--tw-bg-opacity));
          z-index: 0;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
      }
    }
  }
}

.mat-calendar-body-cell-content {
  border-color: transparent;
}

.mat-calendar-body-today {
  border-color: var(--mat-datepicker-calendar-date-today-outline-color);
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  background-color: var(
    --mat-datepicker-calendar-date-hover-state-background-color
  );
}

.mat-calendar-body-selected,
.multi-calendar-active .mat-calendar-body-cell-content {
  background-color: var(
    --mat-datepicker-calendar-date-selected-state-background-color
  );
  color: var(--mat-datepicker-calendar-date-selected-state-text-color);
}

.mat-calendar-body-disabled
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  color: var(--mat-datepicker-calendar-date-disabled-state-text-color);
}

.mat-calendar-body
  .mat-calendar-body-cell-container
  .mat-calendar-body-cell.multi-calendar-active.mat-calendar-body-disabled {
  .mat-calendar-body-cell-content {
    color: var(--mat-datepicker-calendar-date-selected-state-text-color);
    opacity: 0.38;
  }
}

.mat-datepicker-content {
  background-color: colors.$white;
  box-shadow: var(--mat-datepicker-calendar-container-elevation-shadow);

  .mat-button-disabled {
    color: colors.$sort-grey;
    border-color: colors.$sort-grey;
  }
}

.mat-calendar-table-header-divider::after {
  content: '';
  position: absolute;
  top: 0;
  left: -8px;
  right: -8px;
  height: 1px;
  background: var(--mat-datepicker-calendar-header-divider-color);
}

.mat-calendar-table-header th {
  text-align: center;
  padding: 0 0 8px 0;
  color: var(--mat-datepicker-calendar-header-text-color);
  font-size: var(--mat-datepicker-calendar-header-text-size);
  font-weight: var(--mat-datepicker-calendar-header-text-weight);
}

.mat-calendar-body-label {
  height: 0;
  line-height: 0;
  text-align: start;
  padding-left: 4.7142857143%;
  padding-right: 4.7142857143%;
  font-size: var(--mat-datepicker-calendar-body-label-text-size);
  font-weight: var(--mat-datepicker-calendar-body-label-text-weight);
  color: var(--mat-datepicker-calendar-body-label-text-color);
}

.multi-calendar-active:not(.mat-calendar-body-disabled):hover
  .mat-calendar-body-cell-content {
  @apply bg-black-alpha-80 #{!important};
}

.mat-calendar-table-header {
  user-select: none !important;
}

.mat-date-range-input-container {
  @apply w-full;
}

.mat-mdc-floating-label:not(.mdc-floating-label--float-above) {
  max-width: calc(100% - (var(--input-element-height) + 4px));
}

.date-or-time-picker-form-field.mat-mdc-form-field-has-icon-suffix .mat-mdc-form-field-icon-suffix {
  bottom: 0;
}

mat-datepicker-toggle, mat-timepicker-toggle {
  mat-icon, svg {
    height: var(--mdc-icon-button-icon-size) !important;
    width: var(--mdc-icon-button-icon-size) !important;
    color: colors.$sort-grey;
  }

  .mat-mdc-icon-button.mat-mdc-button-base {
    @apply flex items-center justify-center p-0;
  }

  .mat-mdc-icon-button.mat-mdc-button-base {
    --mdc-icon-button-state-layer-size: var(--input-element-height);
  }

  .mat-mdc-icon-button .mat-mdc-button-touch-target {
    height: var(--mdc-icon-button-state-layer-size);
    width: var(--mdc-icon-button-state-layer-size);
  }
}

@screen down-md {
  .services-datepicker {
    .mat-datepicker-content-container {
      max-height: calc(var(--full-height) - 180px);
      @apply overflow-auto;
    }
  }
}
