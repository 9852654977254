$white: #ffffff;

$grey: #4b4b4b;
$light-grey: #ebebeb;

$sort-grey: #9b9b9b;
$sort-grey-alpha-20: rgba(155, 155, 155, 0.2);
$sort-grey-alpha-40: rgba(155, 155, 155, 0.4);

$dark-grey: #232323;
$background-grey: #191919;

$yellow: #eebd22;
$yellow-alpha-10: rgba(238, 189, 34, 0.1);
$yellow-alpha-20: rgba(238, 189, 34, 0.2);
$yellow-alpha-40: rgba(238, 189, 34, 0.4);
$yellow-alpha-700: rgba(238, 189, 34, 0.07);

$orange: #ee9c22;
$pink: #c54aff;
$red: #f4665b;
$red-alpha-700: 'rgba(244, 102, 91, 0.07)';
$red-alpha-500: 'rgba(244, 102, 91, 0.5)';
$green: #68ce75;
$blue: #708fff;
$dark-blue: #0243b2;

$black: #000000;
$black-alpha-50: rgba(0, 0, 0, 0.5);
$black-alpha-60: rgba(0, 0, 0, 0.6);
$black-alpha-80: rgba(0, 0, 0, 0.8);
$black-alpha-90: rgba(0, 0, 0, 0.9);
