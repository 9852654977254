@use 'sizing';

.mat-mdc-raised-button {
  font-weight: 600 !important;

  &.btn-label-full .mdc-button__label {
    width: 100%;
    display: inline-flex;
  }

  &.btn-primary {
    min-width: 70px;

    &.btn-icon {
      min-width: 130px;
    }

    &:not(:disabled),
    &.mat-mdc-button-disabled:disabled {
      @apply bg-black text-yellow border-solid border-2 border-yellow rounded text-base py-1 px-4 uppercase;
    }

    &:hover,
    &:focus-visible {
      @apply opacity-80;
    }

    &:active,
    &.active {
      @apply bg-yellow-alpha-40;
    }
  }

  &.btn-accent {
    min-width: 95px;

    &.btn-icon {
      min-width: 130px;
    }

    &:not(:disabled),
    &.mat-mdc-button-disabled:disabled {
      @apply bg-yellow text-black border-solid border-2 border-yellow rounded text-base py-1 px-4 uppercase;
    }

    &:hover,
    &:focus-visible {
      @apply bg-orange border-orange;
    }

    &:active,
    &.active {
      @apply bg-orange border-orange;
    }

    &.services-btn {
      @apply min-w-[70px];

      &:not(:disabled),
      &.mat-mdc-button-disabled:disabled {
        @apply text-sm h-auto pt-1 pb-0.5 px-4;
      }
    }
  }

  &.btn-basic {
    min-width: 95px;

    &:not(:disabled),
    &.mat-mdc-button-disabled:disabled {
      @apply bg-black text-sort-grey border-solid border-2 border-sort-grey rounded text-base py-1 px-4 uppercase font-medium;
    }

    &:hover,
    &:focus-visible {
      @apply bg-grey;
    }

    &:active,
    &.active {
      @apply bg-sort-grey-alpha-40;
    }

    &.services-btn {
      @apply min-w-[70px];

      &:not(:disabled) {
        @apply text-sm h-auto py-1 px-4;
      }
      &.mat-mdc-button-disabled:disabled {
        @apply text-sm h-auto py-1 px-4 bg-[unset] border-0 text-white opacity-100;
      }

      @screen down-lg {
        @apply min-w-[auto];
      }
    }
  }

  &.btn-secondary {
    min-width: 95px;

    &:not(:disabled),
    &.mat-mdc-button-disabled:disabled {
      @apply bg-sort-grey text-black border-solid border-2 border-sort-grey rounded text-base py-1 px-4 uppercase font-medium;
    }

    &:hover,
    &:focus-visible,
    &:active,
    &.active {
      @apply bg-light-grey border-light-grey;
    }

    &.mobile-reset-btn {
      @screen down-lg {
        @apply cursor-pointer text-white text-base [background-color:unset] border-0 m-0 min-w-[auto];
        @apply h-auto font-medium px-0 #{!important};
      }
    }

    &.services-btn {
      @apply min-w-[70px];

      &:not(:disabled) {
        @apply text-sm h-auto pt-1 pb-0.5 px-4;
      }
      &.mat-mdc-button-disabled:disabled {
        @apply text-sm h-auto pt-1 pb-0.5 px-4 bg-[unset] border-0 text-white opacity-100;
      }

      @screen down-lg {
        @apply min-w-[auto];
      }
    }
  }

  &.mat-mdc-button-disabled:disabled {
    @apply cursor-default pointer-events-none opacity-50 py-1;
  }
}

.mat-mdc-button-base {
  font-weight: 600 !important;

  &.btn-flat-primary {
    min-width: 95px;

    &:not(:disabled),
    &.mat-mdc-button-disabled:disabled {
      @apply bg-none text-yellow text-base py-1 px-4 uppercase;
    }

    &:hover,
    &:focus-visible,
    &:active,
    &.active {
      @apply bg-none text-orange;
    }
  }

  &.btn-flat-basic {
    min-width: 95px;

    &:not(:disabled),
    &.mat-mdc-button-disabled:disabled {
      @apply bg-none text-sort-grey text-base py-1 px-4 uppercase;
    }

    &:hover,
    &:focus-visible,
    &:active,
    &.active {
      @apply bg-none text-light-grey;
    }
  }

  &.mat-mdc-button-disabled:disabled {
    @apply cursor-default pointer-events-none opacity-50 py-1;
  }
}

.mat-mdc-paginator-container {
  .mat-mdc-button-disabled:disabled {
    @apply py-0;
  }
}

.mat-mdc-button-base, .mat-mdc-raised-button {
  .mat-mdc-button-touch-target {
    height: var(--input-element-height);
    @apply inline-flex items-center;
  }
}
