@use 'colors';

.mat-mdc-slider {
  &.mat-slider-light {
    .mdc-slider__thumb-knob {
      &:after {
        content: '';
        height: 24px;
        width: 24px;
        display: block;
        position: absolute;
        left: 0;
        top: -12px;
        transform: translate(-50%);
        border-radius: 50%;
        border: 2px solid colors.$white;
      }
    }

    .mdc-slider__track--inactive {
      background-color: colors.$white;
      opacity: 1;
    }
  }

  &.mdc-slider--disabled {
    input {
      pointer-events: none;
    }
  }
}

.mat-mdc-slide-toggle {
  .mdc-switch .mdc-switch__handle::after,
  .mdc-switch__shadow {
    @apply bg-white;
  }

  .mdc-switch .mdc-switch__track::before {
    @apply bg-sort-grey;
  }

  .mdc-switch--checked {
    @apply bg-yellow-alpha-40 rounded-lg;

    .mdc-switch__icon {
      @apply fill-yellow;
    }

    .mdc-switch__shadow {
      @apply bg-yellow;
    }
  }
}
