mat-progress-bar.mat-mdc-progress-bar {
  height: 2px;
  overflow: hidden;

  .mdc-linear-progress__buffer {
    height: 2px;
    opacity: 1;
    transition: opacity 0.1s ease;
  }

  &[mode='determinate'] {
    .mdc-linear-progress__buffer {
      opacity: 0 !important;
    }
  }
}
