@tailwind screens;

.create-profile-content-body {
  min-height: calc(100vh - 186px);

  @apply px-[18px] py-[30px] mt-4 lg:mb-10 down-lg:mb-14;

  @screen md {
    @apply px-[30px];
  }
}
