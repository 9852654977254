@use 'colors';
@use 'sizing';

.mat-mdc-chip {
  .mdc-evolution-chip__cell--primary {
    @apply justify-center
  }

  &.mat-mdc-standard-chip {
    @apply rounded bg-grey border border-sort-grey min-w-[66px] h-[#{var(--input-element-height)}] #{!important};

    .mdc-evolution-chip__text-label,
    .mdc-evolution-chip__checkmark {
      @apply text-sort-grey text-base #{!important}
    }

    &.mat-accent {
      @apply bg-yellow-alpha-10 border-yellow #{!important};

      .mdc-evolution-chip__text-label,
      .mdc-evolution-chip__checkmark {
        @apply text-yellow #{!important}
      }
    }

    &.mdc-evolution-chip--selected {
      @apply min-w-[95px] #{!important}
    }
  }
}

.mdc-evolution-chip-set__chips {
  @apply gap-4;
}

.mat-mdc-chip-listbox {
  &.mdc-evolution-chip-set .mdc-evolution-chip-set__chips {
    margin-left: 0;
  }

  mat-chip-option.mat-mdc-chip {
    &.mdc-evolution-chip {
      margin: 0;
    }

    &.mat-accent:not(.mat-mdc-chip-selected) {
      border-color: colors.$sort-grey !important;
      background-color: transparent !important;

      .mdc-evolution-chip__text-label {
        color: colors.$sort-grey !important;
      }
    }
  }
}

$gapMap: (
  1: 1,
  '1-5': 1.5,
  2: 2,
  '2-5': 2.5,
  3: 3,
  4: 4,
  5: 5
);

@each $key, $value in $gapMap {
  .chip-gap-#{$key} {
    .mdc-evolution-chip-set__chips {
      @apply gap-#{$value}
    }
  }
}
