@use 'abstracts/mixins';
@use 'abstracts/typography';
@tailwind screens;

$font-family: var(--font-family);
$font-weight-bold: 700;
$font-weight-semi-bold: 600;
$font-weight-normal: 400;
$font-weight-thin: 300;
$font-size-xl: 32px;
$font-size-lg: 24px;
$font-size-md: 16px;
$font-size-sm: 14px;
$md-andi14-font-size: 12px;
$md-andi14-font-size-sm: 10px;
$md-andi14-font-size-title: 26px;


h1.app-headline {
  @include mixins.define-typography(
    $font-size-xl,
    40px,
    $font-weight-semi-bold
  );
}

h2.app-headline {
  @include mixins.define-typography(
    $font-size-lg,
    30px,
    $font-weight-semi-bold
  );
}

@screen down-sm {
  h1.app-headline {
    @include mixins.define-typography(18px, 24px, 700);
  }

  h2.app-headline {
    @include mixins.define-typography(18px, 24px, 400);
  }
}

@screen md-and-i14 {
  h1.app-headline,
  h2.app-headline {
    font-size: $md-andi14-font-size-title;
  }
}
