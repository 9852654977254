@use 'sass:map';

@use '../../../node_modules/@angular/material/index' as mat;

@use '../abstracts/colors';
@use '../abstracts/palettes';
@use '../abstracts/typography';
@use './background-foregraund' as bg-fg;

:root {
  --font-family: 'Roboto';
  --font-family: 'Roboto';
  --white: colors.$white;
  --white-alpha-10: rgba(255, 255, 255, 0.1);
  --grey: #4b4b4b;
  --grey-100: colors.$light-grey;
  --grey-200: colors.$sort-grey;
  --grey-500: colors.$dark-grey;
  --grey-700: colors.$background-grey;
  --yellow: colors.$yellow;
  --orange: colors.$orange;
  --pink: colors.$pink;
  --red: colors.$red;
  --green: colors.$green;
  --blue: colors.$blue;
  --black: colors.$black;
  --mat-select-enabled-arrow-color: #9b9b9b;
}

$primary-palette: mat.m2-define-palette(
  (
    100: colors.$white,
    500: colors.$black,
    700: colors.$black,
    contrast: (
      100: colors.$black,
      500: colors.$yellow,
      700: colors.$white
    )
  )
);

$accent-palette: mat.m2-define-palette(
  (
    100: colors.$white,
    500: colors.$yellow,
    700: colors.$black,
    contrast: (
      100: colors.$black,
      500: colors.$black,
      700: colors.$white
    )
  )
);

$warn-palette: mat.m2-define-palette(
  (
    100: colors.$white,
    500: colors.$red,
    700: colors.$black,
    contrast: (
      100: colors.$black,
      500: colors.$white,
      700: colors.$white
    )
  )
);

$theme: (
  primary: $primary-palette,
  accent: $accent-palette,
  warn: $warn-palette,
  background: bg-fg.$light-theme-background-palette,
  foreground: bg-fg.$light-theme-foreground-palette
);

@include mat.core();

@include mat.all-component-themes(
  (
    color: $theme
  )
);

body {
  background-color: colors.$background-grey;
}

.shadow {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1),
  0 2px 4px rgba(0, 0, 0, 0.1);
}
