.mdc-list-item--disabled .doc-type {
  opacity: .38;
}

.status-approved {
  @apply bg-success text-white;
}

.status-declined {
  @apply bg-warn text-white;
}

.status-not-verified {
  @apply bg-sort-grey text-white;
}
