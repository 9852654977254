@use "colors";

.light-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  @apply rounded bg-light-grey
}

.light-scroll::-webkit-scrollbar {
  @apply w-1 rounded bg-light-grey
}

.light-scroll::-webkit-scrollbar-thumb {
  @apply bg-sort-grey-alpha-20
}
