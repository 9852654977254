@use 'colors';
@use 'mixins';

mat-hint {
  @apply font-normal text-sort-grey mt-0.5;
}


mat-hint, mat-error {
  @apply text-xs;

  @include mixins.small-desktop {
    @apply text-[8px];
  }
}

.mat-mdc-form-field-bottom-align::before {
  @apply h-fit #{!important};
}
