@use 'colors';


.mat-mdc-snack-bar-container  {
  border-radius: 4px;

  .mdc-snackbar__surface {
    background-color: transparent !important;
  }

  .mat-mdc-simple-snack-bar {
    justify-content: center;
  }

  .mdc-snackbar__label {
    text-align: center;
  }
}

.toasty-success{
  background-color: colors.$green;

  &, .mdc-button__label {
    color: colors.$white;
  }
}

.toasty-error{
  background-color: colors.$red;

  &, .mdc-button__label {
    color: colors.$white;
  }
}

.toasty-warning{
  background-color: colors.$yellow;

  &, .mdc-button__label {
    color: colors.$black;
  }
}

.toasty-info{
  background-color: colors.$blue;

  &, .mdc-button__label {
    color: colors.$white;
  }
}
