@use 'colors';

mat-checkbox {
  .mdc-form-field.mat-internal-form-field {
    @apply inline-block;

    .mdc-checkbox {
      vertical-align: top !important;
      height: var(--mdc-checkbox-state-layer-size);
      width: var(--mdc-checkbox-state-layer-size);

      @apply p-0 m-0 overflow-hidden align-sub;

      .mdc-checkbox__background {
        height: var(--mdc-checkbox-state-layer-size);
        width: var(--mdc-checkbox-state-layer-size);

        @apply left-0 top-0;
      }
    }
  }

  &.mat-checkbox-inline-flex .mdc-form-field.mat-internal-form-field {
    @apply inline-flex items-start;
  }

  .mat-mdc-checkbox-touch-target {
    height: 100%;
    width: 100%;
  }

  input:focus-visible
    ~ .mdc-checkbox__ripple
    + .mdc-checkbox__background:after {
    content: '';
    height: calc(100% + 4px);
    width: calc(100% + 4px);
    position: absolute;
    left: -2px;
    top: -2px;
    border: 1px solid colors.$black;
  }

  &.dark-checkbox
    input:focus-visible
    ~ .mdc-checkbox__ripple
    + .mdc-checkbox__background:after {
    border-color: colors.$yellow;
  }
}

.yellow-checkbox {
  &.mat-mdc-checkbox {
    --mat-checkbox-label-text-color: black !important;

    .mdc-checkbox__native-control {
      & ~ .mdc-checkbox__background {
        @apply border-yellow #{!important};

        .mdc-checkbox__checkmark {
          @apply p-[1px];

          .mdc-checkbox__checkmark-path {
            @apply stroke-white;
          }
        }
      }
    }
  }
}

.center-checkbox {
  @apply w-full;

  .mdc-form-field {
    @apply flex items-center #{!important};
  }

  @media screen and (max-width: 410px) {
    &.copy-checkbox {
      .mdc-form-field {
        @apply items-start #{!important};
      }
    }
  }
}

.mat-pseudo-checkbox {
  height: var(--mdc-checkbox-state-layer-size) !important;
  width: var(--mdc-checkbox-state-layer-size) !important;
  margin-right: 12px !important;
}

.mat-pseudo-checkbox.mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked::after {
  height: 2px;
  width: 6px;
}

.ng-invalid.ng-touched {
  &.mat-mdc-checkbox {
    .mdc-checkbox__native-control {
      & ~ .mdc-checkbox__background {
        @apply border-warn #{!important};

        .mdc-checkbox__checkmark {
          @apply p-[1px];

          .mdc-checkbox__checkmark-path {
            @apply stroke-white;
          }
        }
      }
    }
  }
}
