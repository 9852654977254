.flex-between-center {
  @apply flex justify-between items-center;
}

.flex-start-center {
  @apply flex justify-start items-center;
}

.flex-center-center {
  @apply flex justify-center items-center;
}

.inline-flex-start-center {
  @apply inline-flex justify-start items-center;
}
