@mixin define-typography(
  $font-size,
  $line-height: $font-size,
  $font-weight: 400,
  $font-family: var(--font-family),
  $letter-spacing: normal
) {
  font-size: $font-size;
  line-height: $line-height;
  font-weight: $font-weight;
  font-family: $font-family;
  letter-spacing: $letter-spacing;
}

@mixin small-desktop {
  @media (min-width: 1024px) and (max-width: 1600px) {
    @content;
  }
}

@mixin large-desktop {
  @media screen and (min-width: 1600px) {
    @content;
  }
}
