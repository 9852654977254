@use 'mixins';
@tailwind screens;

$screen-m-w-size: 1200px;
$content-max-width: 1300px;
$header-height: 60px;
$input-element-small-desktop-height: 32px;
$input-element-height: 40px;

:root {
  --mat-toolbar-standard-height: 52px;
  --mat-toolbar-mobile-height: 48px;
  --mat-form-field-container-height: 48px;
  --mat-form-field-container-vertical-padding: 8px;
  --mat-form-field-filled-with-label-container-padding-top: 18px;
  --mdc-checkbox-state-layer-size: 18px;
  --mat-badge-legacy-small-size-container-size: 18px;
  --mat-checkbox-label-text-size: 14px;
  --mat-checkbox-label-text-line-height: 16px;
  --mdc-radio-state-layer-size: 24px;
  --mat-badge-small-size-text-size: 12px;
  --mat-badge-small-size-container-overlap-offset: -10px;
  --mat-badge-small-size-line-height: 16px;
  --mdc-dialog-supporting-text-tracking: normal;
  --mdc-dialog-supporting-text-line-height: 20px;
  --mdc-dialog-supporting-text-size: 16px;
  --input-element-height: 40px;
  --mdc-text-button-container-height: var(--input-element-height);
  --mdc-icon-button-state-layer-size: var(--input-element-height);
  --mdc-filled-button-container-height: var(--input-element-height);
  --mdc-protected-button-container-height: var(--input-element-height);
  --mdc-outlined-button-container-height: var(--input-element-height);
  --mdc-icon-button-icon-size: 20px;
  --content-padding: 70px;
}

@include mixins.small-desktop {
  :root {
    --input-element-height: 32px;
    --mat-form-field-container-height: 46px;
    --mdc-checkbox-state-layer-size: 14px;
    --mat-badge-legacy-small-size-container-size: 16px;
    --mat-checkbox-label-text-size: 12px;
    --mdc-radio-state-layer-size: 16px;
    --mat-badge-small-size-text-size: 10px;
    --mat-badge-small-size-container-overlap-offset: -8px;
    --mdc-dialog-supporting-text-size: 14px;
  }
}

@screen lg {
  :root {
    --full-height: 100vh;
  }
}

@screen down-lg {
  .down-lg-content-x-padding {
    padding-left: var(--content-padding);
    padding-right: var(--content-padding);
  }
}

@screen down-md {
  .down-md-content-x-padding {
    padding-left: var(--content-padding);
    padding-right: var(--content-padding);
  }
}

@screen down-xl {
  :root {
    --content-padding: 18px;
  }
}
