@use 'colors';
@tailwind screens;

:root {
  --sidenav-width: 100vw;
  --full-height: calc(var(--real-vh) * 100); /* This is equivalent to 100vh */
}

@screen lg {
  :root {
    --sidenav-width: 240px;
  }
  .mat-sidenav-fixed {
    z-index: 1 !important;
  }
  .sidenav-visible {
    width: unset;

    mat-sidenav-content {
      margin-left: var(--sidenav-width);
      overflow: auto;
    }
  }
}

@screen down-lg {
  mat-sidenav {
    top: -1px !important;
    height: calc(var(--full-height) + 1px);

    mat-nav-list {
      height: 100% !important;
    }
  }
}

mat-nav-list {
  width: var(--sidenav-width);
}

.sidenav-item {
  transition: all 0.2s;
  outline: unset;
  font-size: 14px;

  &:hover,
  &:focus {
    background-color: colors.$yellow-alpha-10;

    * {
      color: colors.$yellow;
    }
  }

  &.active-link {
    background-color: colors.$yellow-alpha-10;
    color: colors.$yellow;
    position: relative;

    .mat-icon {
      color: colors.$yellow;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      left: -15px;
      width: 6px;
      height: 38px;
      background-color: colors.$yellow;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }
}
