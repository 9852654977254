@use 'breakpoints';
@tailwind screens;

.mat-mdc-dialog-container {
  .mdc-dialog__surface {
    @apply bg-transparent shadow-none #{!important};
  }

  .dialog-frame {
    .mat-dialog-body {
      @apply bg-white rounded py-[30px] px-[30px] text-black font-normal;
    }

    .mdc-dialog__title {
      @apply p-0;
    }

    .mat-mdc-dialog-content {
      max-height: calc(var(--full-height) - 180px);
      @apply px-0 py-6;
    }

    .mdc-dialog__content {
      @apply text-black font-normal;
    }

    .mat-mdc-dialog-actions {
      @apply flex flex-wrap w-full justify-end p-0;

      @media screen and (max-width: breakpoints.$bp-sm) {
        .mat-mdc-raised-button {
          @apply text-xs;
        }
      }
    }
  }

  .mdc-dialog__title::before {
    display: none;
  }
}

.cdk-overlay-dark-backdrop {
  @apply bg-black-alpha-90;
}

.mat-mdc-dialog-container .dialog-frame .mat-mdc-dialog-content {
  padding: 4px 0;
  transform: translateZ(0);
}

@screen down-md {
  .cdk-overlay-pane.mat-mdc-dialog-panel {
    max-width: 95vw !important;

    .dialog-frame .mat-dialog-body {
      padding: 20px;
    }
  }

  .mat-mdc-dialog-container .mat-mdc-dialog-title + .mat-mdc-dialog-content {
    max-height: calc(var(--real-vh) * 80 - 80px);
  }
}
