@use 'colors';

.loader-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  border-radius: 3px;
  background-color: rgba(#fafafa, 0.87);
  z-index: 2;

  .loader {
    width: 60px;
    height: 65px;
    position: relative;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    span {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 100%;
      background-color: colors.$yellow;
      margin: 35px 5px;
      &:nth-child(1) {
        animation: bounce 1s ease-in-out infinite;
      }

      &:nth-child(2) {
        animation: bounce 1s ease-in-out 0.33s infinite;
      }

      &:nth-child(3) {
        animation: bounce 1s ease-in-out 0.66s infinite;
      }
    }

    @keyframes bounce {
      0%,
      75%,
      100% {
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
      }

      25% {
        -webkit-transform: translateY(-20px);
        -ms-transform: translateY(-20px);
        -o-transform: translateY(-20px);
        transform: translateY(-20px);
      }
    }
  }
}
