@use 'colors';

.ui-kit-container {
  .app-headline {
    color: colors.$white;
  }

  .dark-checkbox.mat-mdc-checkbox {
    .mdc-checkbox__native-control {
      &~.mdc-checkbox__background {
        @apply border-black #{!important};
      }
    }
  }
}

