@use 'mixins';
@use 'colors';
@tailwind screens;

:root {
  --mdc-protected-button-label-text-tracking: normal;
}

body {
  height: var(--full-height);
}

app-sign-up,
app-sign-up-successful {
  @apply block h-full;
}

.border-unset {
  border: unset;
}

.word-break {
  word-break: break-word;
}

.story-panel {
  @screen down-lg {
    @apply w-full;
  }
}

.all-unset {
  all: unset;
}

.story-container {
  mat-progress-bar.mat-mdc-progress-bar {
    @apply h-1 bg-yellow-alpha-40;

    .mdc-linear-progress__bar-inner {
      @apply border-yellow;
    }
  }
}

.messages-accordion {
  mat-expansion-panel-header {
    .mat-content {
      @apply items-center;
    }

    .mat-expansion-indicator {
      @apply hidden;
    }
  }

  .mat-expansion-panel-header-description {
    @apply block text-black text-sm font-bold;

    p,
    b {
      @apply text-black text-sm m-0;

      @screen down-sm {
        @apply text-xs;
      }
    }
  }

  .message-panel {
    p,
    b {
      @apply text-ellipsis overflow-hidden whitespace-nowrap max-w-44 max-h-6;

      @screen down-sm {
        @apply text-xs;
      }
    }

    @screen down-sm {
      @apply text-sm;
    }
  }
}

.payment-options-accordion,
.bitcoin-faq-accordion {
  .mat-expansion-panel {
    @apply shadow-none #{!important};

    mat-expansion-panel-header {
      all: unset;
      @apply cursor-pointer;

      .mat-expansion-indicator {
        @apply hidden;
      }

      &:hover {
        @apply bg-[unset] #{!important};
      }
    }

    .mat-expansion-panel-body {
      @apply p-0;
    }
  }
}

@screen down-md {
  .home-banner .promo-banner {
    @apply border-0 m-0;
  }
}

.popover {
  &.mat-mdc-menu-panel {
    @apply bg-black border border-yellow rounded my-2.5 w-72 overflow-visible;

    .mat-mdc-menu-content {
      @apply p-4 text-white;
    }
  }

  .popover-arrow {
    @apply absolute right-1 w-0 h-0 border-l-[15px] border-r-[15px] border-solid border-transparent border-b-[15px] border-b-black;
  }

  &.mat-menu-below {
    .popover-arrow {
      @apply -top-2;
    }
  }

  &.mat-menu-above {
    .popover-arrow {
      @apply -bottom-2 rotate-180;
    }
  }

  @screen down-md {
    .popover-arrow.status-popover {
      @apply left-0;
    }
  }

  app-price-popover {
    @apply overflow-auto max-h-[400px] block;

    &::-webkit-scrollbar {
      @apply w-4;
    }

    &::-webkit-scrollbar-thumb {
      border-right: 2px solid colors.$yellow;
    }

    &::-webkit-scrollbar-track {
      @apply bg-black;
    }
  }
}

.text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.mat-mdc-menu-item {
  min-height: 28px !important;
}

mat-icon.mat-icon {
  @apply h-6 w-6 min-w-5 min-h-5 inline-flex items-center justify-center text-[22px];

  @include mixins.small-desktop {
    @apply h-[18px] w-[18px] min-w-[18px] min-h-[18px] inline-flex items-center justify-center text-[18px];
  }
}

mat-icon.mat-icon-sm {
  @apply h-4 w-4 min-w-4 min-h-4 inline-flex items-center justify-center text-[17px];

  @include mixins.small-desktop {
    @apply h-3 w-3 min-w-3 min-h-3 inline-flex items-center justify-center text-[13px];
  }
}

input,
textarea {
  cursor: text;
}

@layer base {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
div[style*='z-index: 999999'][style*='bottom: 88px'],
#launcher {
  @apply z-10 #{!important};
}

* {
  font-family: var(--font-family);
  padding: 0;
  margin: 0;
}

lightbox > .g-btn-close {
  @apply rounded bg-black p-2 top-1 right-2 w-[30px] h-[30px] #{!important};

  svg {
    opacity: 1;
  }
}
