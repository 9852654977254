.roman-list {
  list-style-type: none;
  counter-reset: list-counter;
  padding-left: 0;
}

.roman-list li {
  position: relative;
  margin-left: 10px;
  text-indent: 0;
  padding-left: 30px;
  margin-bottom: 4px;
}

.roman-list li::before {
  counter-increment: list-counter;
  content: "(" counter(list-counter, lower-roman) ") ";
  position: absolute;
  left: 0;
}
