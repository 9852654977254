@use 'mixins';
@use 'typography';
@use 'sizing';

.mat-mdc-form-field-type-mat-select,
.form-field-datepicker,
.mat-mdc-form-field-type-mat-input {
  mat-select,
  .mat-mdc-input-element {
    @apply border border-light-grey rounded py-0 pr-3 pl-3 bg-light-grey text-base font-normal #{!important};

    &:not(textarea) {
      height: var(--input-element-height);
      @apply inline-flex w-full items-center;
    }
  }

  &.mat-form-field-hide-placeholder {
    .mdc-floating-label {
      @apply top-[38px] #{!important};
    }

    @include mixins.small-desktop {
      .mdc-floating-label {
        @apply top-[34px] #{!important};
      }
    }
  }

  &.mat-form-field-disabled,
  .mat-mdc-button-disabled {
    @apply opacity-70;

    span {
      @apply opacity-70;
    }
  }

  .mat-mdc-text-field-wrapper {
    @apply bg-transparent p-0 #{!important};

    &:not(.mdc-text-field--disabled) {
      @apply opacity-80;
    }

    &:not(.mdc-text-field--invalid) {
      .mdc-floating-label--float-above {
        @apply font-semibold #{!important};
      }
    }

    &.mdc-text-field--focused:not(.mdc-text-field--disabled) {
      &.mdc-text-field--filled {
        .mdc-floating-label--float-above {
          @apply font-semibold #{!important};
        }
      }

      .mdc-floating-label--float-above {
        @apply text-yellow font-semibold #{!important};
      }

      mat-select {
        &:not(.mat-mdc-select-empty) {
          .mat-mdc-select-arrow svg {
            @apply fill-yellow;
          }
        }
      }
    }

    mat-select {
      .mat-mdc-select-value-text {
        @apply block w-full;
      }
    }

    .mat-mdc-input-element {
      @apply py-[11px] pr-3 pl-3 font-normal #{!important};
    }

    .mdc-floating-label {
      @apply absolute left-3 font-normal text-sort-grey #{!important};
    }

    .mat-mdc-form-field-focus-overlay {
      @apply hidden;
    }

    .mat-mdc-select-arrow-wrapper {
      @apply transform-none;
    }

    .mat-mdc-form-field-infix {
      @apply pb-0;
    }
  }

  .mdc-line-ripple {
    @apply hidden;
  }
  //}
}

.mat-mdc-form-field-subscript-wrapper .mat-mdc-form-field-error-wrapper {
  @apply p-0 text-[10px];
}

.static-error .mat-mdc-form-field-subscript-wrapper {
  &::before {
    display: none !important;
  }

  .mat-mdc-form-field-error-wrapper {
    @apply static;
  }
}

.mat-mdc-select-min-line {
  @apply capitalize;
}

.mdc-switch,
.mat-mdc-checkbox {
  --mat-checkbox-label-text-color: white !important;

  .mdc-checkbox__native-control {
    & ~ .mdc-checkbox__background {
      @apply border-white #{!important};
    }
  }

  .mdc-switch__ripple,
  .mat-mdc-checkbox-ripple,
  .mdc-checkbox__ripple {
    display: none !important;
  }
}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
  @apply p-0 #{!important};
}

.mat-mdc-form-field-has-icon-suffix:not(.form-field-datepicker) {
  &.mat-form-field-invalid {
    &.mat-focused .mat-mdc-text-field-wrapper .mat-mdc-form-field-icon-suffix,
    .mat-mdc-form-field-icon-suffix {
      @apply text-black;
    }
  }

  .mat-mdc-form-field-icon-suffix {
    @apply absolute text-black right-0 bottom-[7px];
  }

  &.mat-mdc-form-field-type-mat-input {
    .mat-mdc-text-field-wrapper {
      .mat-mdc-input-element {
        @apply pr-10 #{!important};
      }
    }
  }
}

.mat-mdc-form-field-has-icon-prefix {
  &.mat-form-field-invalid {
    &.mat-focused .mat-mdc-text-field-wrapper .mat-mdc-form-field-icon-prefix,
    .mat-mdc-form-field-icon-prefix {
      @apply text-black;
    }
  }

  .mat-mdc-form-field-icon-prefix {
    @apply absolute text-black left-0 bottom-2;
  }

  &.mat-mdc-form-field-type-mat-input {
    .mat-mdc-text-field-wrapper {
      .mat-mdc-input-element {
        @apply pl-10 #{!important};
      }
    }
  }
}

.mdc-floating-label--required {
  &:not(.mdc-floating-label--hide-required-marker) {
    &::after {
      @apply text-red font-bold;
    }
  }
}

mat-form-field mat-error {
  margin-top: 2px;

  &.mat-mdc-form-field-bottom-align:before {
    display: none;
  }
}

mat-select-trigger {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.pre-line-tooltip {
  white-space: pre-line;
  text-align: left;
}

.mat-mdc-paginator-container {
  .mat-mdc-paginator-page-size-select {
    @apply w-[90px] ml-4;

    .mdc-notched-outline__leading,
    .mdc-notched-outline__trailing {
      @apply border-0 #{!important};
    }
    .mat-mdc-form-field-infix {
      @apply p-0;

      .mat-mdc-select {
        @apply bg-light-grey border-light-grey border-0 px-4 py-2;

        .mat-mdc-select-value {
          @apply text-base text-black;
        }
      }
    }
  }

  .mat-mdc-paginator-page-size-label,
  .mat-mdc-paginator-range-label {
    @apply text-sm text-sort-grey;
  }
}

.mat-mdc-floating-label {
  z-index: 10;
}

.mat-mdc-form-field-required-marker:after {
  top: 2px !important;
}

mat-label {
  @apply text-base max-w-full;
}

.mdc-floating-label {
  width: 100%;
  line-height: 0 !important;

  &:not(.mdc-floating-label--float-above) mat-label {
    max-width: calc(100% - 24px);
  }

  mat-label {
    @apply text-base whitespace-nowrap overflow-hidden text-ellipsis inline-block;
  }
}

.mat-mdc-form-field-type-mat-input
  .mdc-floating-label:not(.mdc-floating-label--float-above) {
  cursor: text !important;
}

.mat-mdc-form-field-required-marker {
  @apply relative;

  &:after {
    @apply absolute top-0;
  }
}

@screen down-md {
  .hide-subscribe-wrapper {
    .mat-mdc-form-field-subscript-wrapper {
      @apply hidden;
    }
  }
}

.hide-subscribe-wrapper-full {
  .mat-mdc-form-field-subscript-wrapper {
    @apply hidden;
  }
}
.mat-mdc-paginator-container {
  .mat-mdc-form-field-type-mat-select mat-select {
    @apply h-10 #{!important};
  }
}

@screen down-xl {
  .mat-mdc-paginator-container {
    @apply justify-start #{!important};

    .mat-mdc-paginator-range-actions {
      .mat-mdc-paginator-navigation-next {
        @apply order-2;
      }

      .mat-mdc-paginator-range-label {
        @apply m-0 order-2;
      }
    }
  }
}

@screen down-md {
  .mat-mdc-paginator-container .mat-mdc-paginator-page-size {
    @apply hidden;
  }

  .mat-mdc-paginator-range-actions {
    @apply m-auto;
  }
}

.hide-mat-select-caret .mat-mdc-select-arrow-wrapper {
  display: none;
}

.mdc-list-item__primary-text {
  @apply text-base #{!important};
}
