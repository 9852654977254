@use 'mixins';
@use 'colors';

.mat-button-toggle-group {
  border: unset !important;
  border-radius: unset !important;

  &[aria-disabled="true"], &[disabled="true"] {
    pointer-events: none;
    opacity: 0.7;
  }

  .mat-button-toggle {
    border: 1px solid colors.$sort-grey !important;
    color: colors.$sort-grey !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    .mat-button-toggle-button {
      @apply h-full;
    }

    &:first-child {
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
    }

    &:last-child {
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
    }
  }

  .mat-button-toggle-sm {
    @apply text-xs #{!important};

    .mat-pseudo-checkbox {
      @apply mr-2 #{!important};

      &:after {
        @apply h-[4px] w-[10px] #{!important};
      }
    }
  }

  span {
    font-weight: 400 !important;
  }

  .mat-button-toggle-checked {
    border-color: colors.$black !important;
    background-color: colors.$black !important;
    color: colors.$yellow !important;

    mat-pseudo-checkbox:after {
      color: colors.$yellow !important;
    }
  }

  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: inherit;
  }
}

.mat-mdc-radio-touch-target, .mat-mdc-radio-button .mdc-radio__background, .mat-mdc-radio-button .mdc-radio {
  height: var(--mdc-radio-state-layer-size) !important;
  width: var(--mdc-radio-state-layer-size) !important;
}

.mat-mdc-radio-button .mdc-radio__inner-circle {
  border-width: 12px !important;

  @include mixins.small-desktop {
  border-width: 8px !important;
  }
}

.mat-mdc-radio-button .mdc-radio__background::before {
  left: 0 !important;
  top: 0 !important;
}

.mat-mdc-radio-button, .mat-mdc-radio-button .mdc-label {
  @apply cursor-pointer;
}
