@use 'mixins';

@include mixins.small-desktop {
  .text-2xs {
    font-size: 8px;
    line-height: 10px;
  }

  .text-xs {
    font-size: 10px;
    line-height: 12px;
  }

  .text-sm {
    font-size: 12px;
    line-height: 14px;
  }

  .text-base {
    font-size: 14px;
    line-height: 16px;
  }

 .text-lg {
    font-size: 16px;
    line-height: 18px;
  }

  .text-2xl {
    font-size: 22px;
    line-height: 24px;
  }
}

b, strong {
  @apply font-semibold;
}

body {
  letter-spacing: normal;

  @apply text-sm;
}


h1 {
  @apply text-2xl font-semibold;
}

h2 {
  @apply text-lg font-semibold;
}

h3 {
  @apply text-base font-semibold;
}

h4 {
  @apply text-sm font-semibold;
}

h5 {
  @apply text-xs font-semibold;
}

h6 {
  @apply text-2xs font-semibold;
}
