@tailwind screens;

.scam-alert-dialog-panel {
  ul li {
    list-style-type: disc;
    margin-left: 18px;

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  @screen down-md {
    max-width: 80vw !important;
  }
}
