@use 'colors';
@use 'typography';

.ql-container.ql-snow {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  overflow: hidden;

  @apply h-[120px] w-full down-lg:w-full;

  .ql-editor {
    @apply text-base relative;
    font-family: var(--font-family);
  }
}

.ql-toolbar.ql-snow {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  .ql-stroke {
    @apply stroke-sort-grey;
  }

  .ql-fill {
    @apply fill-sort-grey;
  }
}

.quill-label {
  @apply absolute top-[33px] left-[16px] opacity-0 text-yellow font-semibold text-base transition-[opacity_top_text_0.3s_ease-in-out];

  &.show {
    @apply opacity-100 top-[1px] text-xs;
  }
}

quill-editor.floating-label-active .ql-blank:before {
  display: none;
}

quill-editor {
  .ql-toolbar, .ql-editor {
    @apply bg-light-grey;
  }

  .ql-toolbar {
    @apply border-0 border-t border-sort-grey #{!important};
  }

  &.editor-focused .ql-toolbar {
    @apply border-yellow #{!important};
  }

  &.editor-error .ql-toolbar {
    @apply border-warn #{!important};
  }

  .ql-container {
    &.ql-snow {
      @apply border-0;
    }

    .ql-blank:before {
      font-family: var(--font-family);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      color: colors.$sort-grey;
    }
  }
}


